export default [
  {
    component: 'CNavItem',
    name: 'Accueil',
    to: '/accueil',
    icon: 'cil-home',
  },
  {
    component: 'CNavTitle',
    name: 'Whitelist',
  },
  {
    component: 'CNavItem',
    name: 'QCM',
    to: '/qcm',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Informations HRP',
    to: '/info-hrp',
    icon: 'cil-pencil',
  },
  {
    component: 'CNavItem',
    name: 'Votre Background',
    to: '/perso-rp',
    icon: 'cil-pencil',
  },
  {
    component: 'CNavItem',
    name: 'Entretien',
    to: '/Inscription',
    icon: 'cil-calendar',
  },
  {
    component: 'CNavTitle',
    name: 'Recruteurs',
    recruters: true,
  },
  {
    component: 'CNavItem',
    name: 'Liste joueurs',
    to: '/recruteurs/playerslist',
    icon: 'cil-people',
    recruters: true,
  },
  {
    component: 'CNavItem',
    name: 'Entretiens',
    to: '/recruteurs/entretiens',
    icon: 'cil-calendar',
    recruters: true,
  },
]
