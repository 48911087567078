<template>
  <div>
    <CToaster placement="top-end">
      <CToast v-for="(toast, index) in store.state.toasts" :key="index">
        <CToastHeader
          :style="toast.success ? 'background-color: #4dc374 ; color: white' : 'background-color: #de0000; color: white'"
          closeButton>
          <span class="me-auto fw-bold">{{ toast.title }}</span>
        </CToastHeader>
        <CToastBody>
          {{ toast.content }}
        </CToastBody>
      </CToast>
    </CToaster>
    <div v-if="!loaded">
    </div>
    <router-view v-else-if="store.state.me.id" @refresh-data="getMyData" />
  </div>
</template>

<script>
import {useStore} from 'vuex'
import axios from 'axios'

export default {
  data() {
    return {
      loaded: false
    }
  },
  components: {
  },
  methods: {
    getMyData() {
      if (!this.$cookies.get("token") || this.store.state.me.id) {
        this.loaded = true;
        return;
      }
      axios.get(`${this.store.state.base_url}/api/me/${this.$cookies.get("token")}`, {
        withCredentials: true
      }).then((res) => {
        if (res.data.error) {
          console.log(res.data.error)
          this.$cookies.remove("token");
        } else {
          this.store.state.me = res.data;
        }
      }).catch(() => { })
        .finally(() => {
          this.loaded = true;
        })
    },
    login() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      axios.get(`${this.store.state.base_url}/api/discord/callback`, {
        params: { code: params.get("code") },
        withCredentials: true
      }).then((res) => {
        if (res.data?.error)
          console.log(res.data.error)
        location.href = this.store.state.base_url;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loaded = true;
      });
    },
    getPath() {
      return window.location.pathname.split("/")[1];
    },
  },
  mounted() {
    if (this.getPath().startsWith("login"))
      this.login();
    else if (this.$cookies.get("token"))
      this.getMyData();
    else
      location.href = encodeURI(`https://discord.com/api/oauth2/authorize?client_id=1222979460157145291&redirect_uri=https://panel.visionrp.fr/login&response_type=code&scope=identify email connections`);

  },
  setup() {
    const store = useStore()
    return {
      store,
    }
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
