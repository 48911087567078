<template>
  <CFooter class="ctr">
    <div>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }} VisionRP.</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

<style>
.ctr {
  justify-content: center;
}
</style>
