<template>
  <CDropdown>
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="'https://cdn.discordapp.com/avatars/' + store.state.me.id + '/' + store.state.me.avatar + '?size=512'" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownItem @click="this.logout"> <CIcon icon="cil-lock-locked" /> Logout </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import { useStore } from 'vuex'
import avatar from '@/assets/images/avatars/8.jpg'
export default {
  name: 'AppHeaderDropdownAccnt',
  methods: {
    logout() {
        this.$cookies.remove("token")
        this.store.state.me = {}
        window.location.reload();
    }
  },
  setup() {
    const store = useStore()
    
    return {
      avatar: avatar,
      store
    }
  },
}
</script>
