import {createRouter, createWebHashHistory} from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/accueil',
    children: [
      {
        path: '/accueil',
        name: 'Accueil',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Accueil" */ '@/views/customs/Accueil.vue'),
      },
      {
        path: '/recruteurs',
        name: 'Recruteur',
        redirect: '/recruteurs/playerslist',
      },
      {
        path: '/recruteurs/playerslist',
        component: () => import('@/views/customs/Liste-joueurs.vue'),
        name: "Liste joueurs",
        recruter: true
      },
      {
        path: '/recruteurs/qcm',
        component: () => import('@/views/customs/Qcm-valides.vue'),
        name: "QCM validés",
        recruter: true
      },
      {
        path: '/recruteurs/entretiens',
        component: () => import('@/views/customs/Liste-session.vue'),
        name: "Entretiens",
        recruter: true
      },
      {
        path: '/login',
        component: () => import('@/views/customs/Accueil.vue'),
        name: "login"
      },
      {
        path: '/perso-rp',
        component: () => import('@/views/customs/Perso-rp.vue'),
        name: "Perso-rp"
      },
      {
        path: '/info-hrp',
        component: () => import('@/views/customs/Info-HRP.vue'),
        name: "Info-hrp"
      },
      {
        path: '/Inscription',
        component: () => import('@/views/customs/Inscription.vue'),
        name: "Inscription"
      },
      {
        path: '/qcm',
        component: () => import('@/views/customs/Qcm.vue'),
        name: "QCM"
      },
      {
        path: '/qst',
        component: () => import('@/views/customs/Questions.vue'),
        name: "Questions"
      },
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/customs/Accueil.vue'),
    name: "login"
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/pages/Page404.vue'),
    name: "error404"
  },
  {
    path: '/forbidden',
    component: () => import('@/views/pages/Page403.vue'),
    name: "error403"
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
